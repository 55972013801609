/* -------------------------------------------------------------------------- */
/*                                    DATE                                    */
/* -------------------------------------------------------------------------- */
export const DATE_FORMAT = 'yyyy-MM-dd';

/* -------------------------------------------------------------------------- */
/*                                  NETWORK                                   */
/* -------------------------------------------------------------------------- */
export const SUCCESSFUL_RESPONSE_CODE = 200;

/* -------------------------------------------------------------------------- */
/*                                   ASSETS                                   */
/* --------------------------------------------------------------- ----------- */

// Image formats
const THUMBNAIL = 'thumbnail';
const SMALL = 'small';
const MEDIUM = 'medium';
const LARGE = 'large';
const ORIGINAL = 'original';

export const IMAGE_FORMATS = {
  THUMBNAIL,
  SMALL,
  MEDIUM,
  LARGE,
  ORIGINAL,
};
