import React from 'react';

import './index.scss';

function Spinner() {
  return (
    <div className="loadingio-spinner-dual-ring-qcmsmkaa5l8">
      <div className="ldio-n229gbu9jw">
        <div />
        <div>
          <div />
        </div>
      </div>
    </div>
  );
}

export default Spinner;
